#home{
	padding:0;
	margin: 0;
  margin:0;
}




#welcome {
  
  
  height:100%;


  background:url('img/hero-logo-bg.png') center center no-repeat;

  //
  

  .intro{
    position:relative;
    top:50%;
    @include translateY(-50%);
    text-align: left;
    padding:50px 0;
    height:auto;
  }


  .btn{
    font-weight:700;
  }

}

	#headerbg {
    height: 100%;
    overflow: hidden;
	}

  /* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
  
  .intro {


  
    h1{
      //text-shadow: rgba(0, 0, 0, 0.25) 2px 3px 3px;
      font-weight:600!important;
      color: #fff;
      line-height:0.7;
      margin:10px 0;
      border:none;

      span{
        font-weight:600!important;
        font-size:inherit;
        color:#37474f;
      }
    }

    h2{
      font-size:1.25rem;
      color:#fff;
      text-transform:none;
    }

    p{
      font-weight:500;
      color:#9e9e9e;
      margin-bottom: 25px;
    }

    
  }
  

}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

  .intro {

    h1{
      font-size:2.75rem;
    }

    h2{
      font-size:1.25rem;
      
    }
  }
  
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  
  .intro {

    h1{
      font-size:3rem;
      
    }

    h2{
      font-size:1.75rem;
      
    }
  }

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

  .intro {

    h1{
      
    }

    h2{
      font-size:2rem;
    }
  }

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  
  .intro {
    
    h1{
    font-size:4.5rem;
    margin: 1rem auto 0.75rem;

    }

    h2{
      font-size:2rem;
    }
  }
}