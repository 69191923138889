.branding{
  padding-left:60px;
  font-size:24px!important;
  line-height:60px;
  color:#fff;
  font-weight:700!important;
  
  float:left;
  display:block;

  span{
    font-size:13px;
    color:#fff;
    font-weight:700!important;
    line-height:40px;
    vertical-align:middle;
  }
}


.custom-nav-collapse{
  .branding{
    color:#555f66;
    span{
      color:#37474f;
    }
  }
}

.custom-nav{


  ul.side-nav{
    @extend .blue-grey, .lighten-5, .cyan-text;

    li{
        
      a{
        padding: 0 15px;
        height:30px;
        line-height:30px;

        &:hover{
          background:#cfd8dc;
          transition: all ease-in 0.35s;
        }

        &.active{
          @extend .cyan-text, .cyan, .text-lighten-5;
          transition: all ease-in 0.35s;

          &:hover{
            color:#e0f7fa;
            background-color:#00bcd4!important;
            //@extend .cyan-text, .cyan, .text-lighten-5!important;
          }
        }
      }
    }
  }
}

.custom-nav{



  box-shadow:none;



  .nav-wrapper{
    height:60px
  }



  .custom-brand-logo{
    width:40px;
    height:40px;
    background:url('img/header-logo.png')top left no-repeat;
    top:10px;
    left:10px;
    float:left;
    display:block;


  }

  ul li{
    //@extend .cyan, .darken-1;

    top:-1px;

    a{
      color: #fff;
      font-weight:400;
      text-transform:uppercase;
      font-weight:700;
      font-size:12px;
      height:70px;
      padding: 10px 15px 0;
      border-bottom:3px solid transparent;
      margin-top:1px;

      &:hover{
        color: #fff;
        background:none;
        border-bottom:3px solid #fff;
        transition: all ease-in 0.35s;
      }

      &.active{
        @extend .pink-text;
        border-bottom:3px solid #555f66;
        transition: all ease-in 0.35s;

        &:hover{
          color:#e91e63;
        }
      }
    }
  }
  

  &.custom-nav-fixed-top{
    position:fixed;
    top:0px;
    left: 0px;
    z-index: 1030;
    border-width: 0;
    transition: height ease-in 0.35s, background-color ease-in 0.35s, padding ease-in 0.35s;
    padding:0;
    height:70px;
    color:#fff;
    background-color:rgba(0, 0, 0, 0);
    //border-bottom:1px solid rgba(236,239,241, 0.25);


      &.custom-nav-collapse{
        top:0px;
        padding:0;
        height:70px;
        background-color: #fafafa;
        border-bottom:1px solid rgba(236,239,241, 1);

        a.custom-brand-logo{
          background:url('img/header-logo.png')bottom left transparent no-repeat;
          
        }
        

        li a{
          color: #555f66;;
          transition: color ease-in 0.35s, background-color ease-in 0.35s;

          &:hover{
            color: #555f66;
            background-color:rgba(#000, 0.15);
            border-bottom:3px solid #555f66;
            transition: all ease-in 0.35s;
          }
        }

        
      }
  }
}

nav {

  height:60px;
  
  ul{
    margin:-1px 0 0;
  }

  .button-collapse {
    float: right;
    height: 56px;
    position: relative;
    z-index: 1;
    margin-right:10px;
  }
}