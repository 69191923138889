//BOOTSTRAP GRID AND RESPONSIVE ONLY
@import "../../node_modules/sass-bootstrap-grid-only/assets/stylesheets/_bootstrap-custom.scss";

//MATERIAL CSS WITH NO GRID
@import "../../node_modules/materialize-css/sass/materialize.scss";

@import"font-awesome/font-awesome.scss";
@import "components/typography.scss";

@import "components/_mixins.scss";

@import "components/_home.scss";
@import "components/_about.scss";
@import "components/_nav.scss";
@import "components/_parallax.scss";
@import "components/_services.scss";
@import "components/_contact.scss";
@import "components/_footer.scss";


html{
    min-height: 100% !important;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

body{
  line-height:2rem; 
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  font-size: 1rem;;
  overflow-x:hidden;
  background-color:#fafafa;
  min-height: 100% !important;
  height: 100%;
  color:#555f66;

  margin:0;

  &.no-scroll{
    overflow:hidden;
  }
}

.container-fluid {
	&.container-fluid-flex{
		padding:0;
	}
}

.parallax-container{
	&.parallax-container-tall{
		height:75%;
	}
}

section{
  padding:25px 0 50px;

  header{
    border-bottom:3px solid #cff4ea;
  }

  .content {
    padding-top:75px;
    padding-bottom:75px;
  }
}

.submit{
  background-color:#555f66;
}