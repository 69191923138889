@mixin translateX($x) {
  -ms-transform:translateX($x);
  -webkit-transform:translateX($x);
  -moz-transform:translateX($x);
  -o-transform:translateX($x);
  transform:translateX($x);
}

@mixin translateY($y) {
  -ms-transform:translateY($y);
  -webkit-transform:translateY($y);
  -moz-transform:translateY($y);
  -o-transform:translateY($y);
  transform:translateY($y);
}

@mixin translate($x, $y) {
  -ms-transform:translate($x, $y);
  -webkit-transform:translate($x, $y);
  -moz-transform:translate($x, $y);
  -o-transform:translate($x, $y);
  transform:translate($x, $y);
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

.reset{
  margin:0;
  padding:0;
  borber:none;
}