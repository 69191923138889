#parallax{
  
  padding:0;

  header{
    margin:0;
    display:block;
    border:none;
    text-align:center;
  }

	h1{
    @extend .blue-grey-text, .text-lighten-5;
    text-transform:none;
    border:none;
    font-weight:500!important;
    padding:0;
    margin:0;
	}

  h2{
    color:#cff4ea;
    font-weight:300!important;
  }


}

.valign {
  top:50%;
  left: 0;
  position: absolute;
  width: 100%;
  padding:0;
  margin:0;
  @include translateY(-50%);
  text-align: center;
}