#about{
	.us{

		text-align:center;

		h3{
			font-weight:400;
		}

		img{
			color: #5999ae;
    	height: 150px;
    	width: 150px;
    	border:3px solid #eee;
    	display:block;
    	margin:0 auto;
    	padding:10px;
    	-webkit-filter: grayscale(100%);
    	filter: grayscale(100%);
    	transition: all ease-in 0.35s;

    	&:hover{
    		background:#5a99ae;
    		cursor:pointer;
    		-webkit-filter: none;
    		filter: none;
    		transition: all ease-in 0.35s;
    	}
		}

		p{
			line-height:1.2rem;
			font-size:1rem;
		}
	}
}