/*#services{
	padding-bottom:0;

	.service-list{
		background:#555f67;
		margin-top:50px;
		padding:50px 0 0;
		text-align:center;

		i{
			color: #5999ae;
		}

		h3{
			color:#cff5ea;
			font-size:120%;
			font-weight:500!important;
		}
		p{
			color:#fafafa;
			line-height:24px;
			font-size:14px;
			padding:10px 20px 40px;
		}

		.card-title{
			text-align:center;
			// position:relative;
    	top:40%;
    	left:50%;
    	@include translate(-50%, -50%);

			i{
				display:block;
			}
		}

		.card-content{
			text-align:left;
			p{
				color: #5999ae;
			}
		}
	}


}*/

#services{
	padding-bottom:0;
	.service-list{
		// margin-top:50px;
		padding:50px 0;
		background:#f5f5f5;

		.service{
			padding:30px 0;
			text-align:center;
			transition: all ease-in 0.35s;

			&:hover{
				background:#eee;
				cursor:pointer;
				transition: all ease-in 0.35s;

				i{
					background: #555f67;
	    		color:#fff;
	    		border-color:#5999ae;
	    		transition: all ease-in 0.35s;

				}

				.btn{
					opacity:1;
				}
			}

			i{
				color: #5999ae;
				position:relative;
				z-index:2;
				background: #fff;
	    	border-radius: 50%;
	    	height: 100px;
	    	line-height: 1.5;
	    	padding-top:12px;
	    	width: 100px;
	    	border:3px solid #eee;
	    	transition: all ease-in 0.35s;
			}

			h3{
				color: #5999ae;
				font-size:120%;
				font-weight:500!important;
				border-bottom:1px dotted #5999ae;
				margin:0 20px;
			}
			p{
				// color:#fafafa;
				line-height:24px;
				font-size:14px;
				padding:10px 20px 20px;
			}

			.btn{
				opacity:0;
			}
		}
	}
}