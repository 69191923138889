h1,
h2,
h3,
h4,
h5,
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
.h1,
.h2,
.h3,
.h4,
.h5,
.h1 span,
.h2 span,
.h3 span,
.h4 span,
.h5 span{
  font-family: 'Raleway', sans-serif;
  display:block;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5{
  font-weight:200!important;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
.h1 span,
.h2 span,
.h3 span,
.h4 span,
.h5 span{
  font-weight:300!important;
  font-size:60%;
  color:#757575; // grey darken-1
  max-width:600px;
  margin:0 auto;
  padding: 10px 0 0;
}

p{
	line-height:2rem;
  font-size:1.1rem;
  padding-bottom:20px;
}

h1{
  border-bottom: 3px solid #5a99ae;
}

h3{
  padding:1rem 0;
}

.quote{
  font-weight:300;
  text-align:center;
  font-size:2rem;
  padding:40px 0;
}

header {
  h1{
    display: inline-block;
    line-height: 50px;
    margin-bottom: -3px;
    padding-bottom: 50px;
  }
}
/* Custom, iPhone Retina */ 
@media only screen and (min-width : 320px) {
 
  h1,
  .h1{
    font-size:1.75rem; // -15
    color: #5a99ae; 
    text-transform:lowercase;
  }

  h2,
  .h2{
    font-size:1.6rem;
    color: #37474f; 
  }

  h3,
  .h3{
    font-size:1.05rem;
    color: #5a99ae; 
    font-weight:600!important;
  }

  h4,
  .h4{
    font-size:1.3rem;
    color: #37474f; 
  }

  h5,
  .h5{
    font-size:1.15rem;
    color: #37474f;
  }

  .caption-box {

    h3 {
      font-size: 40px;
      letter-spacing: 5px;
    }

    h4 {
      font-size: 15px;
    }
  }

  

 
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {
  
  h1,
  .h1{
    font-size:2.0rem; // -15
  }

  h2,
  .h2{
    font-size:1.75rem; 
  }

  h3,
  .h3{
    font-size:1.1rem;
  }

  h4,
  .h4{
    font-size:1.45rem;
  }

  h5,
  .h5{
    font-size:1.30rem;
  }

  .caption-box {

    h3 {
      font-size: 50px;
    }

    h4 {
      font-size: 15px;
    }
  }

  
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  
  h1,
  .h1{
    font-size:2.1rem; // -15
  }

  h2,
  .h2{
    font-size:1.95rem; 
  }

  h3,
  .h3{
    font-size:1.15rem;
  }

  h4,
  .h4{
    font-size:1.65rem;
  }

  h5,
  .h5{
    font-size:1.50rem;

  }

  .caption-box {

    h3 {
      font-size: 60px;
    }

    h4 {
      font-size: 16px;
    }
  }

  
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  
  h1,
  .h1{
    font-size:2.25rem; //-15
  }

  h2,
  .h2{
    font-size:2.1rem; 
  }

  h3,
  .h3{
    font-size:1.25rem;
  }

  h4,
  .h4{
    font-size:1.8rem;
  }

  h5,
  .h5{
    font-size:1.65rem;

  }

  .caption-box {

    h3 {
      font-size: 70px;
    }

    h4 {
      font-size: 18px;
    }
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
  
  h1,
  .h1{
    font-size:2.5rem; //-15
  }

  h2,
  .h2{
    font-size:2.35rem; 
  }

  h3,
  .h3{
    font-size:1.25rem;
  }

  h4,
  .h4{
    font-size:2.05rem;
  }

  h5,
  .h5{
    font-size:1.90rem;

  }

  .caption-box {

    h3 {
      font-size: 80px;
    }

    h4 {
      font-size: 20px;
    }
  }
}
