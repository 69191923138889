#contact{
	ul{
		padding:0;
		margin:0 0 0 25px;

		li{
			line-height:30px;

			&:first-child{
				font-weight:600;
			}

			i{
				margin-top:5px;
			}
		}
	}

	form{
		margin-top:20px;

		input:not([type]), 
		input[type=text], 
		input[type=password], 
		input[type=email], 
		input[type=url], 
		input[type=time], 
		input[type=date], 
		input[type=datetime], 
		input[type=datetime-local], 
		input[type=tel], 
		input[type=number], 
		input[type=search], 
		textarea.materialize-textarea {
			border-bottom: 1px solid #9e9e9e;
		}

		label{
			color: #555f67;
		}
	}
}